import React from "react";
import useStyles from "./styles";
import {
  Paper,
  Typography,
  Divider,
  Box,
  IconButton,
  Link,
  Grid,
} from "@mui/material";
import { FaLinkedin, FaTiktok, FaInstagram, FaFacebook } from "react-icons/fa";

export default function Footer() {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const copyrightText = `Happy Mum Organisation ${currentYear} \u00A9`;

  return (
    <Paper square className={`${classes.footer} ${classes.root}`}>
      <Grid container p={3} spacing={1}> 
        {/* Company */}
        <Grid item xs={12} sm={6} md={3} display="flex" justifyContent={{xs: "left", md: "center"}}>
          <Box>
            <Grid
              container
              direction="column"
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 600, color: "#555353", fontsize: "3em" }}
                >
                  Company
                </Typography>
              </Grid>
              <Grid item>
                <Link
                  href="/about-us"
                  underline="hover"
                  sx={{ fontSize: { xs: "1em", md: "1.2em" } }}
                >
                  About Us
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="/contact-us"
                  underline="hover"
                  sx={{ fontSize: { xs: "1em", md: "1.2em" } }}
                >
                  Contact Us
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="/our-team"
                  underline="hover"
                  sx={{ fontSize: { xs: "1em", md: "1.2em" } }}
                >
                  Our Team
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="/faq"
                  underline="hover"
                  sx={{ fontSize: { xs: "1em", md: "1.2em" } }}
                >
                  FAQ
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Popular Guides */}
        <Grid item xs={12} sm={6} md={3} display="flex" justifyContent={{xs: "left", md: "center"}}>
          <Box>
            <Grid container direction="column">
              <Grid item>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 600, color: "#555353" }}
                >
                  Popular Guides
                </Typography>
              </Grid>
              <Grid item>
                <Link
                  href="/nutrition"
                  underline="hover"
                  sx={{ fontSize: { xs: "1em", md: "1.2em" } }}
                >
                  Nutrition
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="/mental-health"
                  underline="hover"
                  sx={{ fontSize: { xs: "1em", md: "1.2em" } }}
                >
                  Mental Health
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="/exercise"
                  underline="hover"
                  sx={{ fontSize: { xs: "1em", md: "1.2em" } }}
                >
                  Exercise
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="/sleep"
                  underline="hover"
                  sx={{ fontSize: { xs: "1em", md: "1.2em" } }}
                >
                  Sleep
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Legal */}
        <Grid item xs={12} sm={6} md={3} display="flex" justifyContent={{xs: "left", md: "center"}}>
          <Box>
            <Grid container direction="column">
              <Grid item>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 600, color: "#555353" }}
                >
                  Legal
                </Typography>
              </Grid>
              <Grid item>
                <Link
                  href="/privacy-policy"
                  underline="hover"
                  sx={{ fontSize: { xs: "1em", md: "1.2em" } }}
                >
                  Privacy Policy
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="/terms-of-use"
                  underline="hover"
                  sx={{ fontSize: { xs: "1em", md: "1.2em" } }}
                >
                  Terms of Use
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="/security"
                  underline="hover"
                  sx={{ fontSize: { xs: "1em", md: "1.2em" } }}
                >
                  Security
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="/data"
                  underline="hover"
                  sx={{ fontSize: { xs: "1em", md: "1.2em" } }}
                >
                  Data
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Resources */}
        <Grid item xs={12} sm={6} md={3} display="flex" justifyContent={{xs: "left", md: "center"}}>
          <Box>
            <Grid container direction="column">
              <Grid item>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 600, color: "#555353" }}
                >
                  Resources
                </Typography>
              </Grid>
              <Grid item>
                <Link
                  href="https://www.paypal.com/donate/?business=WUG22XRZ4LCXC&no_recurring=0&item_name=Thank+you+for+your+donation%21+All+proceeds+will+go+towards+the+web+application.&currency_code=USD"
                  underline="hover"
                  sx={{ fontSize: { xs: "1em", md: "1.2em" } }}
                >
                  Donate
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="https://www.nhs.uk/pregnancy/"
                  underline="hover"
                  sx={{ fontSize: { xs: "1em", md: "1.2em" } }}
                >
                  NHS
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="https://www.acog.org/womens-health/pregnancy"
                  underline="hover"
                  sx={{ fontSize: { xs: "1em", md: "1.2em" } }}
                >
                  ACOG
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href="https://www.webmd.com/baby/default.htm"
                  underline="hover"
                  sx={{ fontSize: { xs: "1em", md: "1.2em" } }}
                >
                  WebMD
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>

      {/* Divider */}
      <Divider
        variant="middle"
        sx={{ margin: "10px 0", borderColor: "black" }}
      />

      {/* Existing Footer Content */}
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "center", sm: "flex-start" }}
        width="100%"
        py={1}
      >
        <Box py={{ xs: 1, sm: 0 }}>
          <Typography sx={{ fontSize: { xs: "1em", md: "1.2em" } }}>
            {copyrightText}
          </Typography>
        </Box>

        {/* <Hidden smUp>
          <Divider
            orientation="horizontal"
            sx={{
              width: "100%",
              margin: "2px 10px",
              borderColor: "black",
            }}
          />
        </Hidden> */}

        {/* <Box
          py={{ xs: 1, sm: 0 }}
          sx={{ textAlign: { xs: "center", md: "left" } }}
        >
          <Typography sx={{ fontSize: { xs: "0.8em", md: "1em" } }}>
            Developed & Designed by Marinos and Timothy Dakis
          </Typography>
        </Box> */}

        {/* Social Media Icons */}
        <Box display="flex" alignItems="center">
          <IconButton
            rel="noopener noreferrer"
            href="https://www.linkedin.com/company/happy-mum-organization"
            target="_blank"
            aria-label="Happy Mum Organisation linkedin"
          >
            <FaLinkedin />
          </IconButton>
          <IconButton
            rel="noopener noreferrer"
            href="https://www.tiktok.com/@happymum.org"
            target="_blank"
            aria-label="Happy Mum Organisation tik tok"
          >
            <FaTiktok />
          </IconButton>
          <IconButton
            rel="noopener noreferrer"
            href="https://www.instagram.com/happymum.org_"
            target="_blank"
            aria-label="Happy Mum Organisation instagram"
          >
            <FaInstagram />
          </IconButton>
          <IconButton
            rel="noopener noreferrer"
            href="https://www.facebook.com/groups/happymumorganisation/            "
            target="_blank"
            aria-label="Happy Mum Organisation facebook"
          >
            <FaFacebook />
          </IconButton>
        </Box>
      </Box>
    </Paper>
  );
}
