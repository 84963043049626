export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT = 'LOGOUT';

export const loginSuccess = (token) => {
  sessionStorage.setItem('authToken', token); // Persist token in session storage
  return {
    type: LOGIN_SUCCESS,
    payload: token,
  };
};

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const logout = () => {
  sessionStorage.removeItem('authToken'); // Remove token from session storage
  return {
    type: LOGOUT,
  };
};