import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";

export default makeStyles((theme) => ({
  root: {
    // Add styles for root if needed
  },
  footerIcons: {
    display: "flex",
    "& a": {
      color: "black",
      paddingRight: 15,
    },
  },
  footer: {
    borderTop: "solid 0.5px black",
    display: "flex",
    flexDirection: "column",
    padding: "5px 10px",
    justifyContent: "flex-start",
    [useTheme().breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
}));
