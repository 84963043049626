import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { Box, Button, Grid, Paper, Typography, Icon } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CookieIcon from "@mui/icons-material/Cookie";

const useStyles = makeStyles((theme) => ({
  banner: {
    zIndex: 1,
    padding: theme.spacing(2),
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0
  },
  buttonsContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      justifyContent: "center",
    },
  },
  acceptButton: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginBottom: 0,
      marginRight: theme.spacing(1),
    },
  },
}));

const CookieBanner = () => {
  const classes = useStyles();
  const [cookies, setCookie] = useCookies(["happy_mum_cookies"]);
  const [showBanner, setShowBanner] = useState(!cookies.happy_mum_cookies);

  const handleAcceptCookies = () => {
    setCookie("happy_mum_cookies", true, { path: "/" });
    setShowBanner(false);
  };

  const handleDeclineCookies = () => {
    setCookie("happy_mum_cookies", false, { path: "/" });
    setShowBanner(false);
  };

  return (
    showBanner && (
      <Paper elevation={3} className={classes.banner}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Typography
            sx={{
              width: "100%",
              textAlign: { xs: "center", md: "left" },
              marginBottom: { xs: "10px", md: "0" },
            }}
          >
            This web application uses cookies to improve your user experience!
            <Icon
              component={CookieIcon}
              style={{ marginLeft: 4, color: "rgb(188 128 128 / 100%)" }}
            />
          </Typography>
          <Grid container className={classes.buttonsContainer} spacing={1}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAcceptCookies}
                className={classes.acceptButton}
              >
                Accept
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={handleDeclineCookies}>
                Decline
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    )
  );
};

export default CookieBanner;
