import React, { Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";
import { Box, ThemeProvider, createTheme } from "@mui/material";
import { CookiesProvider } from "react-cookie";

// Error Boundary
import ErrorBoundary from "./classes/ErrorBoundary";

// Constant components
import NavBarComponent from "./components/NavBarComponent/NavBarComponent";
import Footer from "./components/Footer/Footer";
import BackToTopComponent from "./components/Templates/BackToTopComponent";
import CookieBanner from "./components/Templates/CookieBanner";
import SuspenseFallback from "./components/Templates/SuspenseFallback";
import ScrollToTop from "./components/Templates/ScrollToTop";

// Lazy Components
const Home = React.lazy(() => import("./components/Pages/Home/Home"));
const About = React.lazy(() => import("./components/Pages/About/About"));
const FoodList = React.lazy(() =>
  import("./components/Pages/FoodList/FoodList")
);
const FAQ = React.lazy(() => import("./components/Pages/FAQ/FAQ"));
const Security = React.lazy(() =>
  import("./components/Pages/Security/Security")
);
const Data = React.lazy(() => import("./components/Pages/Data/Data"));
const Auth = React.lazy(() => import("./components/Pages/Auth/Auth"));
const OurTeam = React.lazy(() =>
  import("./components/Pages/OurTeam/OurTeam")
);
const Contact = React.lazy(() => import("./components/Pages/Contact/Contact"));
const ResetPassword = React.lazy(() =>
  import("./components/Pages/ResetPassword/ResetPassword")
);
const AdminDashboard = React.lazy(() =>
  import("./components/Pages/AdminDashboard/AdminDashboard")
);
const UserDashboard = React.lazy(() =>
  import("./components/Pages/UserDashboard/UserDashboard")
);
const Settings = React.lazy(() =>
  import("./components/Pages/Settings/Settings")
);
const PageNotFound = React.lazy(() =>
  import("./components/Pages/PageNotFound/PageNotFound")
);
const TDEECalculator = React.lazy(() =>
  import("./components/Pages/TDEECalculator/TDEECalculator")
);
const NameGenerator = React.lazy(() =>
  import("./components/Pages/PregnancyTools/NameGenerator/NameGenerator")
);
const ExerciseGuide = React.lazy(() =>
  import("./components/Pages/Guides/ExerciseGuide/ExerciseGuide")
);
const EnvironmentalHealth = React.lazy(() =>
  import("./components/Pages/Guides/EnvironmentalHealth/EnvironmentalHealth")
);
const PostpartumCare = React.lazy(() =>
  import("./components/Pages/Guides/PostpartumCare/PostpartumCare")
);
const LabourAndDelivery = React.lazy(() =>
  import("./components/Pages/Guides/LabourAndDelivery/LabourAndDelivery")
);
const PregnancyComplications = React.lazy(() =>
  import(
    "./components/Pages/Guides/PregnancyComplications/PregnancyComplications"
  )
);
const ChronicConditions = React.lazy(() =>
  import("./components/Pages/Guides/ChronicConditions/ChronicConditions")
);
const MedicationAndSupplements = React.lazy(() =>
  import(
    "./components/Pages/Guides/MedicationAndSupplements/MedicationAndSupplements"
  )
);
const MentalHealth = React.lazy(() =>
  import("./components/Pages/Guides/MentalHealth/MentalHealth")
);
const NutritionGuide = React.lazy(() =>
  import("./components/Pages/Guides/NutritionGuide/NutritionGuide")
);
const PrenatalCare = React.lazy(() =>
  import("./components/Pages/Guides/PrenatalCare/PrenatalCare")
);
const Sleep = React.lazy(() => import("./components/Pages/Guides/Sleep/Sleep"));
const PregnancySymptoms = React.lazy(() =>
  import("./components/Pages/Guides/PregnancySymptoms/PregnancySymptoms")
);

const TermsOfUse = React.lazy(() =>
  import("./components/Pages/Legal/TermsOfUse")
);

const PrivacyPolicy = React.lazy(() =>
  import("./components/Pages/Legal/PrivacyPolicy")
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#e90343", // Replace with your desired primary color
    },
    secondary: {
      main: "#000",
    },
  },
  typography: {
    fontFamily: "Montserrat, sans-serif", // Replace with your desired Font Family
  },
});

function App() {
  return (
    <CookiesProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "100vh",
            }}
          >
            <NavBarComponent />
            <Box sx={{ marginTop: "5em", flexGrow: 1 }}>
              <ErrorBoundary>
                <Suspense fallback={<SuspenseFallback />}>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about-us" element={<About />} />
                    <Route path="/contact-us" element={<Contact />} />
                    <Route path="/food-list" element={<FoodList />} />
                    <Route path="/faq" element={<FAQ />} />
                    <Route path="/security" element={<Security />} />
                    <Route path="/data" element={<Data />} />
                    <Route path="/auth" element={<Auth />} />
                    <Route path="/our-team" element={<OurTeam />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route
                      path="/admin-dashboard"
                      element={<AdminDashboard />}
                    />
                    <Route path="/user-dashboard" element={<UserDashboard />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route
                      path="/tdee-calculator"
                      element={<TDEECalculator />}
                    />
                    <Route path="/name-generator" element={<NameGenerator />} />
                    <Route
                      path="/pregnancy-symptoms"
                      element={<PregnancySymptoms />}
                    />
                    <Route path="/exercise" element={<ExerciseGuide />} />
                    <Route path="/sleep" element={<Sleep />} />
                    <Route
                      path="/environmental-health"
                      element={<EnvironmentalHealth />}
                    />
                    <Route
                      path="/postpartum-care"
                      element={<PostpartumCare />}
                    />
                    <Route
                      path="/labour-and-delivery"
                      element={<LabourAndDelivery />}
                    />
                    <Route
                      path="/pregnancy-complications"
                      element={<PregnancyComplications />}
                    />
                    <Route
                      path="/chronic-conditions"
                      element={<ChronicConditions />}
                    />
                    <Route
                      path="/medications-and-supplements"
                      element={<MedicationAndSupplements />}
                    />
                    <Route path="/mental-health" element={<MentalHealth />} />
                    <Route path="/nutrition" element={<NutritionGuide />} />
                    <Route path="/prenatal-care" element={<PrenatalCare />} />
                    <Route path="/terms-of-use" element={<TermsOfUse />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/404" element={<PageNotFound />} />
                    <Route path="*" element={<PageNotFound />} />
                  </Routes>
                </Suspense>
              </ErrorBoundary>
            </Box>
            <BackToTopComponent />
            <CookieBanner />
            <ScrollToTop />
            <Footer />
          </Box>
        </Router>
      </ThemeProvider>
    </CookiesProvider>
  );
}

export default App;
