import React, { useState, useEffect } from 'react';
import { KeyboardArrowUp } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export default function BackToTopComponent() {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    setIsVisible(scrollTop > 0);
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    isVisible && (
      <IconButton
        aria-label="Back to Top"
        onClick={handleScrollToTop}
        sx={{
          position: 'fixed',
          bottom: {xs: '50px', md: '150px'},
          right: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
          transition: 'opacity 0.3s ease',
          opacity: '1',
          background: '#e90343',
          '&:hover': {
            opacity: '1',
            background: '#da0707',
          },
          color: 'white',
          outline: '1px solid #da0707',
        }}
      >
        <KeyboardArrowUp />
      </IconButton>
    )
  );
}
