import React from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import Button from "@mui/material/Button";
import "./NavBarComponent.scss";
import ImageComponent from "../Templates/ImageComponent";
import logo from "../../images/Happy Mum SVG.svg";


import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";

import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions";

const NavbarComponent = () => {
  const isLoggedIn = useSelector((state) => state.auth.token !== null);

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Navbar className="NavBar navbar" bg="white" variant="light" expand="lg">
      <Navbar.Brand className="navbar__logo" href="/">
        <ImageComponent src={logo} alt="Logo" width={80} height={80} />
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        aria-label="Toggle navigation"
      />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto navbar__buttons">
          <Nav.Link href="/" aria-label="Home">
            Home
          </Nav.Link>
          <NavDropdown
            className="navbar__dropdown"
            title="Tools"
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item href="/food-list">Food Catalogue</NavDropdown.Item>
            {/* <NavDropdown.Item href="/name-generator">
              Name Generator
            </NavDropdown.Item> */}
            <NavDropdown.Item href="/tdee-calculator">
              TDEE Calculator
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            className="navbar__dropdown"
            title="Guides"
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item href="/pregnancy-symptoms">
              Pregnancy Symptoms
            </NavDropdown.Item>
            <NavDropdown.Item href="/prenatal-care">
              Prenatal Care
            </NavDropdown.Item>
            <NavDropdown.Item href="/nutrition">Nutrition</NavDropdown.Item>
            <NavDropdown.Item href="/mental-health">
              Mental Health
            </NavDropdown.Item>
            <NavDropdown.Item href="/medications-and-supplements">
              Medications & Supplements
            </NavDropdown.Item>
            <NavDropdown.Item href="/chronic-conditions">
              Chronic Conditions
            </NavDropdown.Item>
            <NavDropdown.Item href="/pregnancy-complications">
              Pregnancy Complications
            </NavDropdown.Item>
            <NavDropdown.Item href="/labour-and-delivery">
              Labour & Delivery Preparation
            </NavDropdown.Item>
            <NavDropdown.Item href="/postpartum-care">
              Postpartum Care
            </NavDropdown.Item>
            <NavDropdown.Item href="/sleep">Sleep</NavDropdown.Item>
            <NavDropdown.Item href="/environmental-health">
              Environmental Health
            </NavDropdown.Item>
            <NavDropdown.Item href="/exercise">Exercise</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            className="navbar__dropdown"
            title="More"
            id="basic-nav-dropdown"
          >
            <NavDropdown.Item href="/about-us">About Us</NavDropdown.Item>
            <NavDropdown.Item href="/contact-us">Contact Us</NavDropdown.Item>
            <NavDropdown.Item href="/our-team">Our Team</NavDropdown.Item>
            <NavDropdown.Item href="/faq">FAQ</NavDropdown.Item>
            <NavDropdown.Item href="/privacy-policy">Privacy Policy</NavDropdown.Item>
            <NavDropdown.Item href="/terms-of-use">Terms Of Use</NavDropdown.Item>
            <NavDropdown.Item href="/security">Security</NavDropdown.Item>
            <NavDropdown.Item href="/data">Data</NavDropdown.Item>

          </NavDropdown>
          <Nav.Link
            href="https://www.paypal.com/donate/?business=WUG22XRZ4LCXC&no_recurring=0&item_name=Thank+you+for+your+donation%21+All+proceeds+will+go+towards+the+web+application.&currency_code=USD"
            rel="noopener noreferrer"
            target="_blank"
            aria-label="Donate"
          >
            Donate
            <OpenInNewRoundedIcon
              sx={{ marginLeft: "5px", fontSize: "18px" }}
            />
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <Nav>
        {isLoggedIn ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handleLogout}
            sx={{
              marginRight: ["auto", "30px"],
            }}
          >
            {" "}
            Log Out
          </Button>
        ) : (
          <Nav.Link href="/auth" className="login-link">
            <Button
              variant="contained"
              color="primary"
              sx={{
                marginRight: ["auto", "30px"],
              }}
            >
              Log In
            </Button>
          </Nav.Link>
        )}
      </Nav>
    </Navbar>
  );
};

export default NavbarComponent;
